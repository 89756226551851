import React from "react";
import "../../styles/custom-bootstrap.scss";
import "../../styles/custom-react-date-picker.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl, Grid, InputLabel, MenuItem, NativeSelect, Select, TextField, Typography } from "@material-ui/core";
import { useFormikContext, getIn } from "formik";


const ServiceTypeView = (props) => {
  const { serviceTypeDisabled, extendedWarrantyClaim, serviceLimit, loadPartial } = props;

  const { t } = useTranslation();
  const customStyles = makeStyles({
    inputField: {
      marginTop: 10,
      marginBottom: 10,
    },
    selectField: {
      marginBottom: 10,
    },
    card: {
      width: "100%",
    },
    customStyleForSelect: {
      color: "#f44336",
      fontSize: "0.75rem",
      marginTop: "3px",
      marginLeft: "14px",
      marginRight: "14px",
    },
  });
  const classes = customStyles();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formik = useFormikContext();

  const handleServiceTypeChange = (event) => {
    formik.handleChange(event);
    loadPartial(event.target.value);
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i class="fa fa-history" aria-hidden="true"></i>
              <strong> {"Quotation Partial"}</strong>
            </div>
            <div className="card-body">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div
                    className={` MuiFormControl-root MuiTextField-root makeStyles-inputField-65 margin-right-10 width-40`}
                    style={{ width: "100%" }}>
                    <Typography variant="subtitle2">{t("Service Type")}</Typography>
                    {serviceTypeDisabled ? 
                      (
                        <label class="font-weight-bold">
                          {t(`keys.extendedWarrantyClaim.serviceType.${extendedWarrantyClaim.quotation?.serviceType}`)}
                        </label>
                      ) : (
                        <select
                          key={`quotation.serviceType`}
                          name={`quotation.serviceType`}
                          onChange={handleServiceTypeChange}
                          value={getIn(formik.values, "quotation.serviceType")}
                          class="form-control">
                          <option hidden value="">
                            {t("messages.selectYourOption")}
                          </option>
                          {serviceLimit.details?.extendedWarranty?.frequencyRemaining > 0 && (
                            <option value="extendedWarranty">{t(`keys.extendedWarrantyClaim.serviceType.extendedWarranty`)}</option>
                          )}
                          {serviceLimit.details?.accidentalDamageAndLiquidDamage?.frequencyRemaining > 0 && (
                            <option value="accidentalDamageAndLiquidDamage">{t(`keys.extendedWarrantyClaim.serviceType.accidentalDamageAndLiquidDamage`)}</option>
                          )}
                          {serviceLimit.details?.replacement?.frequencyRemaining > 0 && (
                            <option value="replacement">{t(`keys.extendedWarrantyClaim.serviceType.replacement`)}</option>
                          )}
                          {serviceLimit.details?.otherCoverage?.frequencyRemaining > 0 && (
                            <option value="otherCoverage">{t(`keys.extendedWarrantyClaim.serviceType.otherCoverage`)}</option>
                          )}
                          {serviceLimit.details?.otherCoverageScreenRepair?.frequencyRemaining > 0 && (
                            <option value="otherCoverageScreenRepair">{t(`keys.extendedWarrantyClaim.serviceType.otherCoverageScreenRepair`)}</option>
                          )}
                          {serviceLimit.details?.otherCoverageBatteryReplacement?.frequencyRemaining > 0 && (
                            <option value="otherCoverageBatteryReplacement">{t(`keys.extendedWarrantyClaim.serviceType.otherCoverageBatteryReplacement`)}</option>
                          )}
                        </select>
                      )
                    }
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceTypeView;
