import React, { useEffect, useState } from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import DeviceDetailsView from "./DeviceDetailsView";
import ServiceLimitTable from "./ServiceLimitTable";
import QuotationDetailsView from "./QuotationDetailsView";
import ClaimDetailsView from "./ClaimDetailsView";
import ExcessFeeTable from "./ExcessFeeTable";
import { Formik } from "formik";
import RepairInvoiceView from "./RepairInvoiceView";
import ServiceCenterView from "./ServiceCenterView"
import UpdateStatusView from "./UpdateStatusView";
import PickUpDetailsView from "./PickUpDetailsView";
import DeliveryDetailsView from "./DeliveryDetailsView";
import AttachedFilesView from "./AttachedFilesView";
import { validateDisableAuthority } from "../../utils/utils";
import { AUTHORITY_PARTNER_AGENT } from "../../constant";
import ServiceTypeView from "./ServiceTypeView";
import ContentLoader from 'react-content-loader';

const attributesColorCircleStyle = (color) => ({
  backgroundColor: color,
  width: `24px`,
  height: `24px`,
  borderRadius: `50%`,
});

const quotationEnabledStatus = [
  "quotationReceivedAndUploaded",
  "quotationReviewInProgress",
  "quotationAwaitingCustomerResponse",
  "customerDeclinedQuotation",
  "customerDisputedQuotation",
  "customerAcceptedQuotation",
  "proceedWithRepair",
  "returnFromAsc",
  "returnDelivering",
  "returnedToCustomer",
  "repairInvoiceReceivedAndUploaded",
  "invoiceReviewInProgress",
  "customerDeclinedInvoice",
  "customerDisputedInvoice",
  "customerAcceptedInvoice",
  "finalReview",
  "reimbursementRequested",
  "paymentInitiated",
  "paymentInProgress",
  "paymentCompleted",
  "completed"
]

const invoiceEnabledStatus = [
  "repairInvoiceReceivedAndUploaded",
  "invoiceReviewInProgress",
  "invoiceAwaitingCustomerResponse",
  "customerDeclinedInvoice",
  "customerDisputedInvoice",
  "customerAcceptedInvoice",
  "finalReview",
  "reimbursementRequested",
  "paymentInitiated",
  "paymentInProgress",
  "paymentCompleted",
  "completed"
]

const serviceCenterDisabledStatus = [
  "new",
  "initiated"
]

const serviceTypeDisabledStatus = [
  "finalReview",
  "paymentInitiated",
  "paymentInProgress",
  "paymentCompleted",
  "completed",
  "canceled"
]

const ExtendedWarrantyDetailsView = ({
  setSaveDisabled,
  onExtendedWarrantyClaimFormSubmit,
  extendedWarrantyClaim,
  editableFields,
  serviceLimit,
  subscription,
  businessEntities,
  countryCode,
  onExtendedWarrantyClaimUpdateStatus,
  onInitiateExtendedWarrantyClaim,
  onAddressFormSubmit,
  editableNewAddressFields,
  encodeFileAsURL,
  userAuthorities,
  showContentLoader
}) => {
  const { t } = useTranslation();

  const [partialEnabled, setPartialEnabled] = useState();

  useEffect(() => {
    setPartialEnabled(extendedWarrantyClaim.quotation?.serviceType && extendedWarrantyClaim.quotation?.serviceType !== "")
  }, [extendedWarrantyClaim.quotation?.serviceType])

  const loadPartial = (serviceType) => {
    if (serviceType && serviceType !== "") {
      setPartialEnabled(true)
    } else {
      setPartialEnabled(false)
    }
  }

  // if (!article) return null;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={editableFields}
      onSubmit={(values, { setSubmitting }) => {
        onExtendedWarrantyClaimFormSubmit(values)
        setSaveDisabled(true);
        setTimeout(function () {
          setSaveDisabled(false);
          setSubmitting(false);
        }, 1000);
      }}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          onSubmit,
          dirty
        } = formik;
        return (
          <form id="extendedWarrantyClaimForm" onSubmit={formik.handleSubmit}>
            {showContentLoader ? 
              <ContentLoader
                width={1400}
                height={400}
                viewBox="0 0 1400 400"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                style={{ width: '100%' }}
                >
                <rect x="27" y="3" rx="4" ry="4" width="20" height="20"/>
                <rect x="67" y="4" rx="10" ry="10" width="85" height="19"/>
                <rect x="188" y="5" rx="10" ry="10" width="169" height="19"/>
                <rect x="402" y="4" rx="10" ry="10" width="85" height="19"/>
                <rect x="523" y="5" rx="10" ry="10" width="169" height="19"/>
                <rect x="731" y="3" rx="10" ry="10" width="85" height="19"/>
                <rect x="852" y="2" rx="10" ry="10" width="85" height="19"/>
                <rect x="1424" y="1" rx="10" ry="10" width="68" height="19"/>
                <rect x="26" y="60" rx="4" ry="4" width="20" height="20"/>
                <rect x="66" y="61" rx="10" ry="10" width="85" height="19"/>
                <rect x="187" y="62" rx="10" ry="10" width="169" height="19"/>
                <rect x="401" y="61" rx="10" ry="10" width="85" height="19"/>
                <rect x="522" y="62" rx="10" ry="10" width="169" height="19"/>
                <rect x="730" y="60" rx="10" ry="10" width="85" height="19"/>
                <rect x="851" y="59" rx="10" ry="10" width="85" height="19"/>
                <circle cx="1456" cy="67" r="12"/>
              </ContentLoader> :
              <div className="custom-bootstrap">
                <ServiceTypeView 
                  serviceTypeDisabled={serviceTypeDisabledStatus.includes(extendedWarrantyClaim?.status)}
                  extendedWarrantyClaim={extendedWarrantyClaim}
                  serviceLimit={serviceLimit}
                  loadPartial={loadPartial}
                  />
                {partialEnabled && (
                  <>
                  <DeviceDetailsView
                    extendedWarrantyClaim={extendedWarrantyClaim}
                    subscription={subscription}
                  />
                  <ClaimDetailsView
                    extendedWarrantyClaim={extendedWarrantyClaim}
                    subscription={subscription}
                  />
                  <ServiceLimitTable
                    serviceLimit={serviceLimit}
                    extendedWarrantyClaim={extendedWarrantyClaim}
                  />
                  {extendedWarrantyClaim?.feeHashMap != null && Object.keys(extendedWarrantyClaim?.feeHashMap).length > 0 && (
                    <ExcessFeeTable
                      extendedWarrantyClaim={extendedWarrantyClaim}
                    />
                  )}
                  {quotationEnabledStatus.includes(extendedWarrantyClaim?.status) && (
                    <QuotationDetailsView
                      extendedWarrantyClaim={extendedWarrantyClaim}
                      userCountryCode={countryCode}
                    />
                  )}
                  {invoiceEnabledStatus.includes(extendedWarrantyClaim?.status) && (
                    <RepairInvoiceView
                      serviceLimit={serviceLimit}
                      extendedWarrantyClaim={extendedWarrantyClaim}
                      userCountryCode={countryCode}
                    />
                  )}
                  {!serviceCenterDisabledStatus.includes(extendedWarrantyClaim?.status) && (
                    <ServiceCenterView
                      extendedWarrantyClaim={extendedWarrantyClaim}
                      businessEntities={businessEntities}
                    />
                  )}
                  <PickUpDetailsView
                    extendedWarrantyClaim={extendedWarrantyClaim}
                    editableNewAddressFields={editableNewAddressFields}
                  />
                  <DeliveryDetailsView
                    extendedWarrantyClaim={extendedWarrantyClaim}
                    onAddressFormSubmit={onAddressFormSubmit}
                    editableNewAddressFields={editableNewAddressFields}
                  />
                  <AttachedFilesView
                    extendedWarrantyClaim={extendedWarrantyClaim}
                    encodeFileAsURL={encodeFileAsURL}
                    userCountryCode={countryCode}
                  />
                  {(extendedWarrantyClaim.status == "new" || extendedWarrantyClaim.status == "initiated" || extendedWarrantyClaim.status == "pendingInternal" || validateDisableAuthority(AUTHORITY_PARTNER_AGENT, userAuthorities)) && <UpdateStatusView
                    extendedWarrantyClaim={extendedWarrantyClaim}
                    onExtendedWarrantyClaimUpdateStatus={onExtendedWarrantyClaimUpdateStatus}
                    onInitiateExtendedWarrantyClaim={onInitiateExtendedWarrantyClaim}
                    userAuthorities={userAuthorities}
                  />}
                </>
              )}
              </div>
            }
          </form>
        );
      }}
    </Formik>
  );
};

export default ExtendedWarrantyDetailsView;
